import Marquee from "react-fast-marquee";

import Heading from "./../molecules/Heading";

import { MARQUEE } from "../../../assets/constants/marquee";
import "./marqueeCard.css";

const MarqueeSlider = () => {
  return (
    <div className="bgFinalClient pt-3">
      <Heading className="center-item heading" text="Prestigious Developers" />

      <div className="marquee-cards-container">
        {/* {MARQUEE?.slice(0, 3).map((image, idx) => {
          const { img } = image;
          return (
            <link
              key={idx}
              rel="preload"
              href={img}
              as="image"
              type="image/webp"
            />
          );
        })} */}
        <Marquee>
          {MARQUEE?.map((image, idx) => {
            const { img, alt = "building developer img" } = image;
            return (
              <div key={idx} className="marqueeCard">
                <img
                  title={alt}
                  className="img-fluid"
                  src={img}
                  alt={alt}
                  loading="lazy"
                  width={"160px"}
                  height={"160px"}
                />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default MarqueeSlider;
