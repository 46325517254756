export const COUNTRY_CODE = {
  INDIA: "+91",
  UNITED_STATES: "+1",
  SWITZERLAND: "+41",
  AUSTRALIA: "+61",
};

const { INDIA } = COUNTRY_CODE;

const COUNTRY_CODES = [INDIA];
export default COUNTRY_CODES;
