import { useEffect, useState } from "react";

import Heading from "../../../globalComponents/molecules/Heading";
import CustomLink from "../../../CustomLink";
import HoverTemplateCard from "../../../homePage/template/HoverTemplateCard";

import { TEMPLATE_BUTTON_LIST } from "../../../../assets/constant";
import "./TemplateMob.css";

const CITY_IMG = {
  BG_IMG2: "https://www.wealth-clinic.com/property_media/67581703666273.jpg",
  BG_IMG3:
    "https://png.pngtree.com/background/20210715/original/pngtree-night-view-of-high-rise-buildings-in-urban-district-picture-image_1312997.jpg",
};

const Card = () => {
  const { BG_IMG3 } = CITY_IMG;
  // const [backGroundImg, setBackGroundImg] = useState(null);
  const [backGroundImg, setBackGroundImg] = useState();

  const handleMouseEnter = (buttonImg) => {
    setBackGroundImg(`${buttonImg}`);
  };

  const handleMouseLeave = () => {
    // setBackGroundImg(null);

    setBackGroundImg(BG_IMG3);
  };
  useEffect(() => {
    setBackGroundImg((prevImg) => (prevImg ? prevImg : BG_IMG3));
  }, [backGroundImg, BG_IMG3]);

  return (
    <div className="mobOurFootPrints" style={{ color: "#000" }}>
      <div className="px-2">
        <Heading
          fontSize="1rem"
          fontWeight="650"
          text={"Our Footprints"}
          className="OurFootPrintsTitle d-flex justify-content-center my-2"
        />

        <Heading
          fontSize=".75rem"
          className="footprintsParagraph d-flex justify-content-center"
          style={{ maxHeight: "auto" }}
          text="From coveted metros to growing suburbs, you’ll find us wherever you seek. We’re here to fulfil all your property aspirations be it a dream home for family, a second home for vacation, a vibrant shop for business, a dynamic office space for growth, or a farmland to build your own castle. Whatever you want…Wherever you want."
        ></Heading>
      </div>
      <div className="row">
        {TEMPLATE_BUTTON_LIST.slice(0, 6)?.map((ele, id) => {
          const { cityName, logo, img, img2 } = ele;
          return (
            <CustomLink
              to={`/${cityName}`}
              key={id}
              className="col-4 center-item"
              style={{ color: "#000" }}
            >
              <HoverTemplateCard
                cityImg={logo}
                imgOnHover={img2}
                onMouseEnter={() => {
                  handleMouseEnter(img);
                }}
                onMouseLeave={handleMouseLeave}
                cityName={cityName}
              />
            </CustomLink>
          );
        })}
      </div>
    </div>
  );
};

export default Card;
