import { PropTypes } from "prop-types";
import useIntersectionObserver from "./hooks/useIntersectionObserver";
import "./customImage.css";

const CustomImage = ({ imageSrc, alt, loading, ...props }) => {
  const [targetSectionRef, interSectingStatus] = useIntersectionObserver(false);

  return (
    <div ref={targetSectionRef} className="custom-image-container">
      {interSectingStatus ? (
        <img {...props} alt={alt} src={imageSrc} loading={loading || "lazy"} />
      ) : (
        <div className="placeHolderImg"></div>
      )}
    </div>
  );
};

export default CustomImage;

CustomImage.prototype = {
  imageSrc: PropTypes.string.isRequired,
  altText: PropTypes.string,
  threshold: PropTypes.number,
  alt: PropTypes.string,
};
