import { Link, useNavigate } from "react-router-dom";

import CustomLink from "../../CustomLink";
import InputCustom from "./../molecules/InputCustom";
import { ButtonDarkBlue } from "./../molecules/ButtonDarkBlue";
import SocialMediaIcons from "./SocialMediaIcons";

import URL from "../../../assets/constants/url";
import { URL_PARAMS } from "./../../../assets/constants/url";
import { COMPANY_LOGO } from "../../../assets/constants/companyLogos";
import { CITIES } from "../../../assets/constants/cities.js";
import "./footer2.css";

const CONTACT_DETAILS = {
  CONTACT_NUMBER: "+919089222000",
  CUSTOMER_CARE_EMAIL: "customercare@wealth-clinic.com",
};

const Footer2 = () => {
  const { CONTACT_NUMBER, CUSTOMER_CARE_EMAIL } = CONTACT_DETAILS;
  const navigate = useNavigate();
  const handleNavigate = () => navigate(URL.HOME);
  return (
    <footer className="row m-0 p-4 px-0 px-sm-4">
      <div className="col-lg-3 col-md-12">
        <div className="d-flex flex-wrap  w-100">
          <div className="row m-0  py-lg-2 mb-md-4">
            <div className="col-4 mb-lg-3 col-sm-4 col-lg-12">
              <div className="footer-logo">
                <img
                  className="img-fluid"
                  alt="Company Logo"
                  src={COMPANY_LOGO}
                  onClick={handleNavigate}
                />
              </div>
            </div>

            <div className="col-8 col-sm-8 col-lg-12 my-lg-3 px-0 px-sm-3 vertical-center">
              <ul className="contactWithUs">
                <li className="vertical-center">
                  CALL US :
                  <div className="my-1">
                    <a className="dailDeco" href={`tel:${CONTACT_NUMBER}`}>
                      <span>{CONTACT_NUMBER}</span>
                    </a>
                  </div>
                </li>
                <li className="vertical-center ">
                  EMAIL US :
                  <div className="my-1">
                    <a
                      className="dailDeco"
                      href={`mailto:${CUSTOMER_CARE_EMAIL}`}
                    >
                      <span>{CUSTOMER_CARE_EMAIL}</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="my-3 vertical-center">
              <SocialMediaIcons />
            </div>

            <div className="my-3">
              <InputCustom
                placeholder={"Subscribe to our newsletter"}
                className="rounded-1 text-center"
              />
              <ButtonDarkBlue className="rounded-1" name="Subscribe Now" />
            </div>
          </div>
        </div>
      </div>

      <aside className="col-lg-8 col-md-12">
        <div className="footerAside">
          <div className="row">
            <FooterLinks title="COMPANY" links={footerLinksData.company} />
            <FooterLinks title="LATEST" links={footerLinksData.latest} />
            <div className="col-6 col-sm-6 col-md-3">
              <ul className="category">
                <li>
                  <h4 className="heading-footer">REAL ESTATE IN INDIA</h4>
                </li>
                {CITIES.REAL_ESTATE.map((city, idx) => (
                  <li key={idx}>
                    <CustomLink to={`/${city}`}>Projects in {city}</CustomLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <ul className="category">
                <li>
                  <h4 className="heading-footer">POPULAR SEARCHES</h4>
                </li>
                {CITIES.POPULAR_SEARCHES.map((search, index) => (
                  <li key={index}>
                    <CustomLink to={`/${search}`}>{search}</CustomLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </footer>
  );
};

export default Footer2;

const FooterLinks = ({ title, links }) => {
  return (
    <div className="col-6 col-sm-6 col-md-3">
      <ul className="category">
        <li>
          <h4 className="heading-footer">{title}</h4>
        </li>
        {links.map((link, idx) => (
          <li key={idx}>
            <Link to={link.to}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const footerLinksData = {
  company: [
    { label: "About Us", to: URL.ABOUT },
    {
      label: "Exclusive Projects",
      to: `${URL.SEARCH}?${URL_PARAMS.EXCLUSIVE_PROPERTY}=true`,
    },
    { label: "Terms & Conditions", to: URL.TERMS_AND_CONDITIONS },
    { label: "Blogs", to: URL.BLOGS },
    { label: "Career", to: URL.CAREER },
    { label: "Contact Us", to: URL.CONTACT_US },
    { label: "Awards", to: URL.AWARD },
  ],
  latest: [
    {
      label: "New Launches",
      to: `${URL.SEARCH}?${URL_PARAMS.NEW_LAUNCHES}=true`,
    },
    { label: "EMI Calculator", to: URL.EMI_CALCULATOR },
    { label: "Happy Customers", to: URL.HAPPY_CUSTOMER },
    { label: "Media", to: URL.MEDIA_COVERAGE },
    { label: "Privacy Policy", to: URL.PRIVACY_POLICY },
    { label: "Disclaimer", to: URL.DISCLAIMER },
  ],
};
