import { lazy } from "react";
import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";

import Footer from "./globalComponents/footer2/Footer";
import PatchButton from "./PatchButton";
// import BackButton from "./BackButton";

import useScrollOnTop from "../hooks/useScrollOnTop";

const LazyMicrosoftClarityScript = lazy(() =>
  import("./MicrosoftClarityScript")
);

const Body = ({ children }) => {
  // useScrollOnTop(isMobile ? 0 : 500);
  useScrollOnTop(0);
  return (
    <div className="body">
      <LazyMicrosoftClarityScript />
      {/* <Disclaimer /> */}
      {children}
      <Footer />
      <PatchButton />
      {/* <ToastContainer position="bottom-center" /> */}
    </div>
  );
};

export default Body;
