import { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (threshold = 0.5) => {
  const targetedRef = useRef(null);
  const [interSectingStatus, setInterSectingStatus] = useState(false);

  useEffect(() => {
    const targetRef = targetedRef.current;

    const handleIntersectionCallBack = ([entry]) => {
      if (entry.isIntersecting) {
        setInterSectingStatus(entry.isIntersecting);
      }
      //   else {
      //     setInterSectingStatus(false);
      //   }
    };

    const observer = new IntersectionObserver(handleIntersectionCallBack, {
      threshold: 0.5,
    });

    if (targetRef) {
      observer.observe(targetRef);
      //   console.log(observer.observe(targeted));
    }

    return () => {
      if (targetRef) observer.unobserve(targetRef);
    };
  }, [threshold]);

  return [targetedRef, interSectingStatus];
};

export default useIntersectionObserver;
