import Slider from "react-slick";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { extractImageAttributes } from "../../../helpers/extractAttributes";
import { getFullUrl } from "../../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../../assets/constants";
import TEMPLATE_BACKGROUND from "./templateBackground.webp";
import "./newTemplateSlider.css";

const TemplateCard = ({ imgHeight }) => {
  const [homePageData, isLoading] = useApiFetcher(
    getFullUrl("/api/home-pages?populate=*")
  );

  if (!homePageData || isLoading) {
    return (
      <div className="templateSliderContainer">
        <div className="h-100">
          <img
            className="img-fluid"
            alt={"wealth-clinic"}
            title={"wealth clinic template"}
            src={`${TEMPLATE_BACKGROUND}`}
            // loading="eager"
            loading="eager"
          />
        </div>
      </div>
    );
  }
  const API_IMAGES = extractImageAttributes(
    homePageData[0]?.attributes?.templates?.data
  );
  const preLoadImage = API_IMAGES[0]?.img || TEMPLATE_BACKGROUND;
  const otherImagesToPreLoad = API_IMAGES.slice(1).map((image) => image.img);

  function preLoadImages(images) {
    images?.forEach((img) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = img;
      link.as = "image";
      document.head.appendChild(link);
    });
  }

  preLoadImages([preLoadImage, ...otherImagesToPreLoad]);

  const settings = {
    dots: false,
    infinite: API_IMAGES.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="templateSliderContainer">
      <Slider {...settings} className="">
        {API_IMAGES.map((image, idx) => {
          const {
            img = TEMPLATE_BACKGROUND,
            alt = "template img",
            title = "",
          } = image || EMPTY_OBJECT;

          return (
            <div key={idx} className="h-100">
              {/* <CustomImage
                imageSrc={img}
                alt={alt}
                title={title}
                style={{ height: imgHeight || "100vh" }}
                loading={idx === 0 ? "eager" : "lazy"}
              /> */}
              <img
                className="img-fluid"
                alt={alt}
                title={title}
                src={img}
                loading={idx === 0 ? "eager" : "lazy"}
                style={{ height: imgHeight || "100vh" }}
              />
              {/* <video className="border border-danger" autoPlay loop muted>
                    <source src={VIDEO_URL} type="video/mp4" />
                  </video> */}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default TemplateCard;
