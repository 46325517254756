// import img1 from "../marque/ATS.png"
import img1 from "../marque/ATS.png";
import img2 from "../marque/Ace.png";
import img3 from "../marque/CRC.png";
import img4 from "../marque/Fusion.png";
import img5 from "../marque/Godrej.png";
import img6 from "../marque/M3M.png";
import img7 from "../marque/Mahagun-Builder-Logo.png";
import img8 from "../marque/Migsun.png";
import img9 from "../marque/Nimbus.png";
import img10 from "../marque/Orion.png";

export const MARQUEE = [
  {
    img: img1,
    alt: "ats img",
  },
  {
    img: img2,
    alt: "Ace img",
  },

  {
    title: "",
    img: img3,
    alt: "CRC img",
  },
  {
    title: "",
    img: img4,
    alt: "Fusion img",
  },
  {
    title: "",
    img: img5,
    alt: "Godrej img",
  },
  {
    title: "",
    img: img6,
    alt: "M3M img",
  },
  {
    title: "",
    img: img7,
    alt: "Mahagun-Builder-Logo img",
  },
  {
    title: "",
    img: img8,
    alt: "Migsun img",
  },

  {
    title: "",
    img: img9,
    alt: "Nimbus img",
  },
  {
    title: "",
    img: img10,
    alt: "Orion img",
  },
];

export const MARQUEE2 = [
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/DLF_logo.svg/800px-DLF_logo.svg.png",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4TIwvtntdlDeg4qb3vzEDZmzT-AuHakFO4A&usqp=CAU",
  },

  {
    img: "https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2022/04/11/179570-godrej-inds.jpg?itok=lnPyEmHK",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsaZUYA2vgilPJf1V2m-qIaoCtsXVplzsJevAkWdcvOAs4RgpZX8RVCNoAVLZXFkACF9s&usqp=CAU",
  },
  {
    img: "https://www.equitybulls.com/equitybullsadmin/uploads/The%20Phoenix%20Mills%20Limited%202.jpg",
  },
  {
    img: "https://www.equitybulls.com/equitybullsadmin/uploads/PVP%20Ventures%20Limited%20Logo.jpg",
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/DLF_logo.svg/800px-DLF_logo.svg.png",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4TIwvtntdlDeg4qb3vzEDZmzT-AuHakFO4A&usqp=CAU",
  },

  {
    img: "https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2022/04/11/179570-godrej-inds.jpg?itok=lnPyEmHK",
  },
];
