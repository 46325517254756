import "./ReraDisclosureSection.css";

const ReraDisclosureSection = () => {
  return (
    <div className="custom-bg-dark row m-0 px-5">
      {reraData.map((reraInfo, idx) => {
        // function ReraInfoDisplay({ reraInfo }) {}
        const { state, reraNumber, link } = reraInfo;
        return (
          <div className="col-sm-6 col-md-4 col-lg-3  my-3 px-3" key={idx}>
            <div className="linksText">
              {`${state || ""} RERA Number: ${reraNumber || ""} 
              (${link || ""})`}
            </div>
          </div>
        );
      })}

      <div className="col-sm-6 col-md-4 col-lg-3  my-3 px-2">
        <div className="linksText">NYS Anti-Discrimination Disclosure</div>
      </div>
    </div>
  );
};

export default ReraDisclosureSection;

const reraData = [
  {
    state: "Uttar Pradesh",
    reraNumber: "UPRERAAGT10521",
    link: "https://www.up-rera.in",
  },
  {
    state: "Bihar",
    reraNumber: "BRERAA71023/03/A-419/2022",
    link: "https://rera.bihar.gov.in/",
  },
  {
    state: "Delhi",
    reraNumber: "DLRERA2019A0113",
    link: "https://www.rera.delhi.gov.in",
  },
  {
    state: "Uttarakhand",
    reraNumber: "UKREA01230000388",
    link: "http://ukrera.org.in:8080/rerauk/",
  },

  {
    state: "Karnataka",
    reraNumber: "PRM/KA/RERA/1251/446/AG/200922/002039",
    link: "https://rera.karnataka.gov.in/",
  },

  {
    state: "Haryana",
    reraNumber: "HRERA-PKL-REA-670-2021",
    link: "https://haryanarera.gov.in/",
  },
];
