import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Carousel, Image, Modal } from "antd";
import { useRef } from "react";

import "./gallerySlider.css";
import { useEffect } from "react";
import { useCallback } from "react";

const GallerySlider = ({ images, handleModalClose, visibility }) => {
  const carouselRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  }, []);

  const handleNext = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePrev, handleNext]);

  return (
    <div className="">
      <Modal
        className="modelContainer"
        onCancel={handleModalClose}
        open={visibility}
        footer={null}
        width="85%"
        centered
      >
        <div className="popUpCarouselContainer">
          <Carousel
            ref={carouselRef}
            arrows={false}
            infinite={true}
            style={{ position: "relative" }}
          >
            {images.map((innerImgData, idx) => (
              <Image key={idx} src={innerImgData?.img} className="img-fluid" />
            ))}
          </Carousel>

          <div className="controlBtnBox">
            <div className="leftBtn" onClick={handlePrev}>
              <FaChevronLeft />
            </div>
            <div className="rightBtn" onClick={handleNext}>
              <FaChevronRight />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GallerySlider;

//<GallerySlider
//images={imagesData}
//handleModalClose={handleModalClose}
//visibility={isModalVisible}
///>
