import { useParams } from "react-router-dom";
import { useState } from "react";

import ButtonDarkBlue from "../../../globalComponents/molecules/ButtonDarkBlue";
import CallSvg from "../../../globalComponents/molecules/CallSvg";
import Heading from "../../../globalComponents/molecules/Heading";
import InputCustom from "../../../globalComponents/molecules/InputCustom";
import SelectOption from "../../../globalComponents/molecules/SelectOption";
import ThankYouMessage from "../../../globalComponents/ThankYouMessage";

import useDeviceSize from "../../../../hooks/useDeviceSize";
import { submissionOnZoho } from "../../../../assets/constants/zoho";
import { ALL_CITIES } from "../../../../assets/constants/cities";
import { FORM_NAMES } from "../../../../assets/constants/formNames";
import { PROCESS_MESSAGE } from "../../../../assets/constants/messages";
import COUNTRY_CODES, {
  COUNTRY_CODE,
} from "../../../../assets/constants/countryCoude";
// import styles from "./PropertyForm.module.css"; // Add a CSS module for error styling

const PROPERTY_OPTIONS = ["Commercial", "Residential"];

const iconStyle = {
  width: "30px",
  height: "30px",
  transform: "rotate(-45deg)",
  marginRight: "0.25rem",
  border: "1px solid orange",
  borderRadius: "50%",
  padding: "0.25rem",
  color: "var(--global-logo-color)",
};

const CITY_PAGE_FORM = {
  // formName: FORM_NAMES.CITY_PAGE_FORM_NAME,
  countryCode: COUNTRY_CODE.INDIA,
  name: "",
  email: "",
  phone: "",
  propertyType: "",
  cityName: "",
};

const PropertyForm = ({ className }) => {
  const { city = FORM_NAMES.CITY_PAGE_FORM_NAME } = useParams();
  const isMobile = useDeviceSize();

  const [formValue, setFormValue] = useState(CITY_PAGE_FORM);

  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const { name, email, phone, propertyType, cityName, countryCode } = formValue;

  const handleInput = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleSelectOption = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!name.trim()) newErrors.name = "Full Name is required.";
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!phone.trim()) {
      newErrors.phone = "Phone Number is required.";
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Phone Number must be 10 digits.";
    }
    if (!propertyType) newErrors.propertyType = "Property Type is required.";
    if (!cityName) newErrors.cityName = "City Name is required.";

    return newErrors;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setIsBtnDisable(true);
      await submissionOnZoho({ ...formValue, formName: city });
      setIsBtnDisable(false);
      setIsSuccessfullySubmitted(true);
      setFormValue(CITY_PAGE_FORM);
    }
  };

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <div className="cityPageForm col-md-12">
      <div className="d-flex my-4 center-item">
        <div>
          <CallSvg style={iconStyle} />
        </div>
        <Heading
          text={"Contact Our Real Estate Experts"}
          fontSize={isMobile ? "1rem" : "1.25rem"}
          color="111"
          fontWeight="600"
          style={{ lineHeight: "1.5rem" }}
          overFlow="visible"
        />
      </div>

      <form
        className={`border rounded-3 ${className}`}
        onSubmit={handleSubmitForm}
        style={{ position: "relative" }}
      >
        <div className="form-group">
          <InputCustom
            name="name"
            value={name}
            onChange={handleInput}
            placeholder="Enter Name"
            className="px-3 rounded-1"
            required
          />
          {errors.name && <div>{errors.name}</div>}
        </div>

        <div className="form-group">
          <InputCustom
            name="email"
            value={email}
            onChange={handleInput}
            placeholder="Enter Email"
            className="px-3 rounded-1"
            required
          />
          {errors.email && <div>{errors.email}</div>}
        </div>

        <div className="row p-0 m-0">
          <div className="col-3 p-0">
            <SelectOption
              name="countryCode"
              data={COUNTRY_CODES}
              value={countryCode}
              onChange={handleSelectOption}
              className="rounded-1 ps-1"
              selectText={COUNTRY_CODE.INDIA}
            />
          </div>
          <div className="col-md-9 px-0 ps-1">
            <InputCustom
              name="phone"
              value={phone}
              onChange={handleInput}
              placeholder="Phone Number"
              className="px-3 pr-4 rounded-1 w-100"
              required
            />
            {errors.phone && <div>{errors.phone}</div>}
          </div>
        </div>

        <div className="form-group">
          <SelectOption
            name="propertyType"
            data={PROPERTY_OPTIONS}
            value={propertyType}
            onChange={handleSelectOption}
            className={"rounded-1"}
            selectText="Select Property Type"
          />
          {errors.propertyType && <div>{errors.propertyType}</div>}
        </div>

        <div className="form-group">
          <SelectOption
            name="cityName"
            data={ALL_CITIES}
            value={cityName}
            onChange={handleSelectOption}
            className={"rounded-1"}
            selectText="Select City"
          />
          {errors.cityName && <div>{errors.cityName}</div>}
        </div>

        <ButtonDarkBlue
          type="submit"
          name={
            isBtnDisable
              ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
              : "Contact Now"
          }
          className={"px-3 rounded-1"}
          disabled={isBtnDisable}
        />
      </form>
      {isSuccessfullySubmitted && (
        <ThankYouMessage onComplete={handleThankYouComplete} />
      )}
    </div>
  );
};

export default PropertyForm;
