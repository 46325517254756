import Footer2 from "./Footer2";
import CopyRightFooter from "./CopyRightFooter";
import FooterTextContent from "./FooterTextContent";
import ReraDisclosureSection from "./ReraDisclosureSection";
import StickySocialIcons from "../stickySocialIcons/StickySocialIcons";

import useDeviceSize from "../../../hooks/useDeviceSize";
import "./CopyRightSection.css";

export const Footer = () => {
  const isMobile = useDeviceSize();
  return (
    <>
      <Footer2 />
      <ReraDisclosureSection />
      <FooterTextContent />
      <CopyRightFooter />
      {!isMobile && <StickySocialIcons />}
      {/* <BackButton /> */}
    </>
  );
};

export default Footer;
