import useDeviceSize from "./../../../hooks/useDeviceSize";

import AWARD_IMG from "../../../assets/homePic/award.png";
import SQUARE_FT_IMG from "../../../assets/homePic/squareft-deliver.png";
import HAPPY_CUSTOMER_IMG from "../../../assets/homePic/happy family .png";
import REAL_ESTATE_IMG from "../../../assets/homePic/real-est.png";
import "./style.css";

const MileStones = () => {
  const isMobile = useDeviceSize();

  return (
    <section className="container  mt-5 ">
      <div className={`row achievements ${isMobile ? "mobile" : "desktop"}`}>
        {MILE_STONES.map((mileStone, idx) => {
          const { img, alt, text } = mileStone;
          return (
            <div key={idx} className="col-lg-3 col-md-4 col-6 text-center mb-4">
              <img src={img} alt={alt} loading="lazy" />
              <h4 className="achievementsImgText">{text}</h4>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MileStones;

const MILE_STONES = [
  {
    img: AWARD_IMG,
    alt: "Award",
    text: `Best Real Estate 
    Agency Since 2012`,
  },
  {
    img: HAPPY_CUSTOMER_IMG,
    alt: "Happy Customers",
    text: "15K+ Happy Families",
  },
  {
    img: SQUARE_FT_IMG,
    alt: "Square Feet Delivered",
    text: "15 Million Sq Ft Space Delivered",
  },
  {
    img: REAL_ESTATE_IMG,
    alt: "Real Estate Projects",
    text: "15+ Commercial & Residential\nProjects Delivered",
  },
];
