import { Link } from "react-router-dom";
import { memo, useCallback, useState } from "react";

import SearchSuggestionBar from "./SearchSuggestionBar";
import BudgetSelect from "./BudgetSelect";
import MultiSelectDropdown from "./../MultiSelectDropdown";

import { CITY_NAME, BUILDING_TYPE } from "../../assets/IMG";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "./../../assets/constants/index";
import "./AdvancedSearchBar.css";

const MemoMultiSelectDropdown = memo(MultiSelectDropdown);
const MemoBudgetSelect = memo(BudgetSelect);

const DROP_DOWNS_TYPE = {
  cityName: EMPTY_ARRAY,
  selectType: [],
  budget: "",
};

const AdvancedSearchBar = () => {
  const [dropDownValue, setDropDownValue] = useState(DROP_DOWNS_TYPE);
  const [searchBarValue, setSearchedBarValue] = useState(EMPTY_OBJECT);
  const [budget, setBudget] = useState(null);

  const handleCitySelection = useCallback((data) => {
    setDropDownValue((older) => ({ ...older, cityName: data }));
  }, []);

  const handleTypeSelection = useCallback((data) => {
    setDropDownValue((older) => ({ ...older, selectType: data }));
  }, []);

  return (
    <header className="my-2 advancedSearchBar">
      <div className="col-md-5 d-flex">
        <div className="col-md-4 mr-3 d-none d-lg-block">
          <MemoMultiSelectDropdown
            name={"Select City"}
            data={CITY_NAME}
            cb={handleCitySelection}
          />
        </div>

        <div className="col-md-3 mr-3 d-none d-lg-block border">
          <MemoMultiSelectDropdown
            name={"Type"}
            data={BUILDING_TYPE}
            cb={handleTypeSelection}
          />
        </div>

        <div className="col-md-5 border d-none d-lg-block">
          <MemoBudgetSelect getBudget={setBudget} />
        </div>
      </div>

      <div className="col-md-5">
        <SearchSuggestionBar getSearchedValue={setSearchedBarValue} />
      </div>

      <div className="col-md-1 btn-width">
        <button
          className="fw-bold text-white filterSearchBtn"
          style={{ background: "var(--global-text-color)" }}
        >
          <Link
            to={"/search"}
            state={{ ...dropDownValue, searchBarValue, budget }}
            className="searchBtn"
          >
            Search
          </Link>
        </button>
      </div>
    </header>
  );
};

export default AdvancedSearchBar;
